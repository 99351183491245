import { createAction, props } from '@ngrx/store';

export enum EQuestionnaireAction {
  LOAD = '[questionnaire] load data',
  VERIFY = '[questionnaire] verify data',
}

export const actionLoadQuestionnaire = createAction(
  EQuestionnaireAction.LOAD,
  props<any>()
);

export const actionVerifyQuestionnaire = createAction(
  EQuestionnaireAction.VERIFY,
  props<{ isRequired: boolean }>()
);
