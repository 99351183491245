import { createAction, props } from '@ngrx/store';
import { IListConfigLedger } from './ledger.reducer';

export enum EConfigLedger {
  LOAD = '[ConfigLedger] load data',
  DROPDOWN = '[ConfigLedger] load dropdown',
}

export const actionConfigLedger = createAction(
  EConfigLedger.LOAD,
  props<{ config: IListConfigLedger[] }>()
);

export const actionDropdownLedger = createAction(
  EConfigLedger.DROPDOWN,
  props<{ dropdown: {} }>()
);
